<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('mentorship.mentorship') }}
      </template>
    </RMPageHeader>
    <div class="row m-b-20">
      <div class="col-lg-6">
        <p>{{ $t("mentorship.mentorshipIntro") }}</p>
      </div>
      <div class="col-lg-6">
        <h4>{{ $t("mentorship.howToBecomeAMentor") }}</h4>
        <p v-if="isUserLoggedIn">{{ $t("mentorship.becomingAMentor") }}</p>
        <p v-else v-html="$t('mentorship.becomingAMentorNotLoggedIn')"/>
        <p>
          <b-button
            variant="primary"
            :disabled="!isUserLoggedIn"
            v-on:click="makeUserAMentor()"
          >
            {{ $t("mentorship.becomeAMentor") }}
          </b-button>
        </p>
      </div>
    </div>
    <div class="row m-b-20">
      <div class="col-md-2"></div>
      <div class="col-md-8" style="border: 1px solid">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
          class="my-carousel"
        >
          <b-carousel-slide :img-src="imageLinks['mentorMyStudents' + '_' + currentLocale]"/>
          <b-carousel-slide :img-src="imageLinks['mentorMyGroups' + '_' + currentLocale]"/>
          <b-carousel-slide :img-src="imageLinks['mentorMyGroup' + '_' + currentLocale]"/>
          <b-carousel-slide :img-src="imageLinks['mentorTaskList' + '_' + currentLocale]"/>
          <b-carousel-slide :img-src="imageLinks['mentorResults' + '_' + currentLocale]"/>
          <b-carousel-slide :img-src="imageLinks['studentMyAssignments' + '_' + currentLocale]"/>
          <b-carousel-slide :img-src="imageLinks['studentTaskList' + '_' + currentLocale]"/>
          <b-carousel-slide :img-src="imageLinks['studentTask' + '_' + currentLocale]"/>
        </b-carousel>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row p-b-10">
      <div class="col">
        <h3>{{ $t("mentorship.mentorshipPackages") }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p v-html="$t('mentorship.packetsDescription')"/>
      </div>
    </div>
    <div v-if="loadingMentorshipPackages" class="row">
      <div class="col-12">
        <b-skeleton class="height-200"></b-skeleton>
      </div>
    </div>
    <div v-else-if="!loadingMentorshipPackages" class="row">
      <div
        class="col-xs-12 col-sm-6 col-md-6 col-xl-4 col-xxl-3 col-xxxl-3"
        v-for="(program, idx) in mentorshipPackages"
        :key="idx"
      >
        <RMPanel
          :title="getProgramTitle(program, idx)"
          :header-class="getProgramHeaderClass(program)"
        >
          <template v-slot:panelBody>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.programs.maxGroups") }}:</span>
              <div><b>&#8804; {{ program.limits.maxGroups }}</b></div>
            </div>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.programs.maxStudents") }}:</span>
              <div><b>&#8804; {{ program.limits.maxStudents }}</b></div>
            </div>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.programs.maxStudentsPerGroup") }}:</span>
              <div><b>&#8804; {{ program.limits.maxStudentsPerGroup }}</b></div>
            </div>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.programs.maxStudentAssignmentsPerWeek") }}:</span>
              <div><b>&#8804; {{ program.limits.maxStudentAssignmentsPerWeek }}</b></div>
            </div>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.programs.maxGroupAssignmentsPerWeek") }}:</span>
              <div><b>&#8804; {{ program.limits.maxGroupAssignmentsPerWeek }}</b></div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.programs.priceFactor") }}:</span>
              <div><b>{{ program.factor }}</b></div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.programs.studentAssignmentPrice") }}:
                <i v-if="program.studentAssignmentPrice > 0" v-b-modal.modal-student-assignment-price class="fa fa-question-circle"/>
              </span>
              <div>
                <div v-if="program.studentAssignmentPrice === 0">
                  <b>{{ $t("mentorship.programs.free").toUpperCase() }}</b>
                </div>
                <div v-else>
                  <b>{{ program.studentAssignmentPrice }}</b>
                  <i class="fas fa-carrot fa-carrot-rm m-l-10"/>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.programs.groupAssignmentPrice") }}:
                <i v-if="program.groupAssignmentPrice > 0" v-b-modal.modal-group-assignment-price class="fa fa-question-circle"/>
              </span>
              <div>
                <div v-if="program.groupAssignmentPrice === 0">
                  <b>{{ $t("mentorship.programs.free").toUpperCase() }}</b>
                </div>
                <div v-else>
                  <b>{{ program.groupAssignmentPrice }}</b>
                  <i class="fas fa-carrot fa-carrot-rm m-l-10"/>
                </div>
              </div>
            </div>
          </template>
        </RMPanel>
      </div>
    </div>
    <b-modal
      id="modal-student-assignment-price"
      :title="$t('mentorship.programs.studentAssignmentPrice')"
      ok-only
      header-class="rm-panel-heading"
    >
      <div class="d-flex justify-content-center" v-html="$t('mentorship.studentAssignmentPriceCalculation')"/>
    </b-modal>
    <b-modal
      id="modal-group-assignment-price"
      :title="$t('mentorship.programs.groupAssignmentPrice')"
      ok-only
      header-class="rm-panel-heading"
    >
      <div class="d-flex justify-content-center" v-html="$t('mentorship.groupAssignmentPriceCalculation')"/>
    </b-modal>
  </div>
</template>

<script>
import RMPanel from "@/components/common/RMPanel.vue";
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import {mentorshipService} from "@/_services/mentorship.service";
import {handleError, showSweetAlert} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import {isUserLoggedIn} from "@/_helpers";

export default {
  name: "Mentorship",
  components: {RMPageHeader, RMPanel},
  data() {
    return {
      loadingMentorshipPackages: false,
      assigningMentor: false,
      mentorshipPackages: [],
      slide: 0,
      sliding: null,
      imageLinks: {
        mentorMyStudents_si: require("@/assets/images/mentorship/my_students_si.png"),
        mentorMyGroups_si: require("@/assets/images/mentorship/my_groups_si.png"),
        mentorMyGroup_si: require("@/assets/images/mentorship/group_si.png"),
        mentorTaskList_si: require("@/assets/images/mentorship/task_list_si.png"),
        mentorResults_si: require("@/assets/images/mentorship/results_si.png"),
        studentMyAssignments_si: require("@/assets/images/mentorship/student_my_assignments_si.png"),
        studentTaskList_si: require("@/assets/images/mentorship/student_task_list_si.png"),
        studentTask_si: require("@/assets/images/mentorship/student_task_si.png"),
        mentorMyStudents_us: require("@/assets/images/mentorship/my_students_us.png"),
        mentorMyGroups_us: require("@/assets/images/mentorship/my_groups_us.png"),
        mentorMyGroup_us: require("@/assets/images/mentorship/group_us.png"),
        mentorTaskList_us: require("@/assets/images/mentorship/task_list_us.png"),
        mentorResults_us: require("@/assets/images/mentorship/results_us.png"),
        studentMyAssignments_us: require("@/assets/images/mentorship/student_my_assignments_us.png"),
        studentTaskList_us: require("@/assets/images/mentorship/student_task_list_us.png"),
        studentTask_us: require("@/assets/images/mentorship/student_task_us.png"),
      }
    }
  },
  computed: {
    isUserLoggedIn() {
      return isUserLoggedIn();
    },
    currentLocale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
    getProgramTitle(program, idx) {
      const localeName = this.$t(`mentorship.packets.${program.name}`)
      return `${idx + 1}. ${localeName}`;
    },
    getProgramHeaderClass(program) {
      return `rm-panel-heading-${program.color}`;
    },
    listMentorshipPackages() {
      this.loadingMentorshipPackages = true;

      mentorshipService.listMentorshipPackages().then((data) => {
        this.mentorshipPackages = data.data;
        this.loadingMentorshipPackages = false;
      }).catch((error) => handleError(error, router, this)).finally(() => {
        this.loadingMentorshipPackages = false;
      })
    },
    makeUserAMentor() {
      this.assigningMentor = true;

      mentorshipService.assignMember().then((data) => {
        showSweetAlert(data.message, this);
      }).catch((error) => handleError(error, router, this)).finally(() => {
        this.assigningMentor = false;
      })
    }
  },
  created() {
    this.listMentorshipPackages();
  }
}
</script>

<style>
/* Change color of carousel controls */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}
</style>